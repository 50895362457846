import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, delay, filter, firstValueFrom, from, Observable, of, skip, Subject, switchMap, takeUntil } from 'rxjs';
import { Book, BookCategory, BookRequestResponse } from '../models/book.model';
import { BooksProvider } from '../providers/books.provider';
import { ProfileState } from '../store/profile.state';
import { BookFiltersState } from '../store/book-filters.state';
import { DefaultProjectState } from '../store/default-project.state';
import { Feature, LoggerService } from './logger.service';
import { AppState } from '../store/app.state';
import { differenceInWeeks } from 'date-fns';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageService } from './language.service';
import { Translatable } from '../models/translation.model';

export enum ShelfType {
  New = 'new',
  Popular = 'popular',
  ContinueReading = 'continue-reading',
  FeaturedActive = 'featured-active',
  Featured = 'featured',
  Category = 'category',
  Favorites = 'favorites',
  ReadAgain = 'read-again',
}

/**
 * The purpose of this service is to keep track & data of all the data in the different book shelves
 * so that you don't have to call up the api every time.
 */

@Injectable({ providedIn: 'root' })
export class BookShelfService implements OnDestroy {
  constructor(
    private booksProvider: BooksProvider,
    private profileState: ProfileState,
    private appState$: AppState,
    private bookFiltersState$: BookFiltersState,
    private defaultProjectState$: DefaultProjectState,
    private loggerService: LoggerService,
    private languageService: LanguageService,
  ) {}

  initialized$ = new BehaviorSubject(false);
  // User shelves
  continueReadingBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  myFavoritesBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  readItAgainBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  // Featured active shelve
  featuredActiveBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  featuredActiveBooksTitle$ = new BehaviorSubject<undefined | Translatable>(undefined);
  featuredActiveId$ = new BehaviorSubject<number | null>(null);
  // General shelves
  featuredBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  popularBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  newBooks$: BehaviorSubject<Array<Book>> = new BehaviorSubject<Array<Book>>([]);
  // Categories shelves
  categoriesBooks$: BehaviorSubject<Array<BookCategory>> = new BehaviorSubject<Array<BookCategory>>([]);

  private destroyed$ = new Subject<void>();

  async initialize(): Promise<void> {
    await this.getUserBooks();
    await this.getWorldReaderBooks();

    // Need to reload the shelves if the project id has changed or if the filters has changed
    combineLatest([this.bookFiltersState$.bookFilters$, this.defaultProjectState$.defaultProject$])
      .pipe(skip(1), takeUntil(this.destroyed$), delay(1))
      .subscribe(() => this.getWorldReaderBooks());

    this.initialized$.next(true);

    return Promise.resolve();
  }

  async updateFavoriteBooks(): Promise<void> {
    const profileId = this.profileState.currentProfile$.value?.id;

    if (profileId) {
      const books =
        (await firstValueFrom(this.booksProvider.getFavoritesBooks(profileId)).catch(error => {
          this.loggerService.error(error, {
            context: 'BookShelfService::updateFavoriteBooks cant load books',
            feature: Feature.SHELF,
            status: error.status,
          });
        })) || [];

      this.myFavoritesBooks$.next(books);
    } else {
      this.myFavoritesBooks$.next([]);
    }
  }

  async updateContinueReadingBooks(): Promise<void> {
    const profileId = this.profileState.currentProfile$.value?.id;
    if (profileId) {
      const books =
        (await firstValueFrom(this.booksProvider.getReadingHistory(profileId)).catch(error => {
          this.loggerService.error(error, {
            context: 'BookShelfService::updateContinueReadingBooks cant load books',
            feature: Feature.SHELF,
            status: error.status,
          });
        })) || [];

      this.continueReadingBooks$.next(books);
    } else {
      this.continueReadingBooks$.next([]);
    }
  }

  async updateReadItAgainBooks(): Promise<void> {
    const profileId = this.profileState.currentProfile$.value?.id;

    if (profileId) {
      const books =
        (await firstValueFrom(this.booksProvider.getFinishedBooks(profileId)).catch(error => {
          this.loggerService.error(error, {
            context: 'BookShelfService::updateReadItAgainBooks cant load books',
            feature: Feature.SHELF,
            status: error.status,
          });
        })) || [];

      this.readItAgainBooks$.next(books);
    } else {
      this.readItAgainBooks$.next([]);
    }
  }

  getBookRequest(type: ShelfType, categoryId?: number): Observable<Array<Book>> {
    switch (type) {
      // Store features / popular & new shelves data in bookShelveService
      case ShelfType.Featured:
        return this.featuredBooks$;
      case ShelfType.Popular:
        return this.popularBooks$;
      case ShelfType.New:
        return this.newBooks$;
      case ShelfType.ContinueReading:
        return this.continueReadingBooks$;
      case ShelfType.Favorites:
        return this.myFavoritesBooks$;
      case ShelfType.ReadAgain:
        return this.readItAgainBooks$;
      case ShelfType.FeaturedActive:
        return this.featuredActiveBooks$;
      case ShelfType.Category:
        if (!categoryId) {
          this.loggerService.error('BookShelfService::getBookRequest', {
            feature: Feature.ACTIVITY,
            context: 'No category ID passed for category shelf',
          });
          return of([]);
        }
        return this.bookFiltersState$.bookFilters$.pipe(
          switchMap(filters => {
            return this.booksProvider.getBooksByCategory(categoryId as number, filters.selectedGrade?.id, filters.selectedLanguage?.code);
          }),
        );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getPagedBooksRequest(type: ShelfType, categoryId?: number, offset = 0, limit = 20): Observable<BookRequestResponse | undefined> {
    const selectedGradeId = this.bookFiltersState$.bookFilters$.value.selectedGrade?.id;
    const selectedLanguageCode = this.bookFiltersState$.bookFilters$.value.selectedLanguage?.code;
    const profileId = this.profileState.currentProfile$.value?.id;

    switch (type) {
      case ShelfType.Featured:
        return this.booksProvider.getFeaturedBooksPaginated(selectedGradeId, selectedLanguageCode, offset, limit);
      case ShelfType.Popular:
        return this.booksProvider.getPopularBooksPaginated(selectedGradeId, selectedLanguageCode, offset, limit);
      case ShelfType.New:
        return this.booksProvider.getNewBooksPaginated(selectedGradeId, selectedLanguageCode, offset, limit);
      case ShelfType.FeaturedActive:
        return this.initialized$.pipe(
          filter(initialized => initialized),
          switchMap(() => {
            return this.featuredActiveId$.value
              ? this.booksProvider.getFeaturedActiveBooksPaginated(this.featuredActiveId$.value, selectedGradeId, selectedLanguageCode, offset, limit)
              : from([]);
          }),
        );

      case ShelfType.ContinueReading:
        return profileId ? this.booksProvider.getReadingHistoryPaginated(profileId, offset, limit) : from([]);
      case ShelfType.Favorites:
        return profileId ? this.booksProvider.getFavoritesBooksPaginated(profileId, offset, limit) : from([]);
      case ShelfType.ReadAgain:
        return profileId ? this.booksProvider.getFinishedBooksPaginated(profileId, offset, limit) : from([]);
      case ShelfType.Category:
        if (!categoryId) {
          console.error('No category Id passed');
        }
        return this.bookFiltersState$.bookFilters$.pipe(
          switchMap(filters => {
            return this.booksProvider.getBooksByCategoryPaginated(
              categoryId as number,
              filters.selectedGrade?.id,
              filters.selectedLanguage?.code,
              offset,
              limit,
            );
          }),
        );
    }
  }

  private async getUserBooks(): Promise<void> {
    await this.updateFavoriteBooks();
    await this.updateContinueReadingBooks();
    await this.updateReadItAgainBooks();
  }

  private async getWorldReaderBooks(): Promise<void> {
    // Need to reload the shelves if the project id has changed or if the filters has changed
    const selectedGradeId = this.bookFiltersState$.bookFilters$.value.selectedGrade?.id;
    const selectedLanguageCode = this.bookFiltersState$.bookFilters$.value.selectedLanguage?.code;

    const featuredBooks =
      (await firstValueFrom(this.booksProvider.getFeaturedBooks(selectedGradeId, selectedLanguageCode)).catch(error => {
        this.loggerService.error(error, {
          context: 'BookShelfService::getWorldReaderBooks - featuredBooks cant load books',
          feature: Feature.SHELF,
          status: error.status,
        });
      })) || [];

    // Featured active shelf
    const featuredActive = await firstValueFrom(this.booksProvider.getFeaturedActive()).catch((error: HttpErrorResponse) => {
      if (error.status === 404) {
        // Do nothing in this case
        if (this.featuredActiveBooks$) {
          this.featuredActiveBooksTitle$.next(undefined);
          this.featuredActiveId$.next(null);
        }

        this.featuredActiveBooks$.next([]);
      } else {
        this.loggerService.error(error, {
          context: 'BookShelfService::getWorldReaderBooks - getFeaturedActive cant load books',
          feature: Feature.SHELF,
          status: error.status,
        });
      }
    });

    if (featuredActive) {
      this.featuredActiveBooksTitle$.next(featuredActive.translatableTitle);
      this.featuredActiveId$.next(featuredActive.id);
      const featuredActiveBooks =
        (await firstValueFrom(this.booksProvider.getFeaturedActiveBooks(featuredActive.id, selectedGradeId, selectedLanguageCode)).catch(error => {
          this.loggerService.error(error, {
            context: 'BookShelfService::getWorldReaderBooks - featuredAtiveBooks cant load books',
            feature: Feature.SHELF,
            status: error.status,
          });
        })) || [];

      this.featuredActiveBooks$.next(featuredActiveBooks);
    }

    /**
     * Popular books & New books (Week based on app.firstLaunch state)
     * Week 1: Books from 1 to 20 (offset: 0)
     * Week 2: Books from 21 to 40 (offset: 20)
     * Week 3: Books from 41 to 60 (offset: 40)
     */

    const offset = [0, 20, 40];
    const diffInWeeks = Math.abs(differenceInWeeks(this.appState$.app$.value.firstAccess, new Date()));
    const offsetToApply = offset[diffInWeeks % 3] || 0;

    const popularBooks =
      (await firstValueFrom(this.booksProvider.getPopularBooks(selectedGradeId, selectedLanguageCode, offsetToApply)).catch(error => {
        this.loggerService.error(error, {
          context: 'BookShelfService::getWorldReaderBooks - popularBooks cant load books',
          feature: Feature.SHELF,
          status: error.status,
        });
      })) || [];

    const newBooks =
      (await firstValueFrom(this.booksProvider.getNewBooks(selectedGradeId, selectedLanguageCode, offsetToApply)).catch(error => {
        this.loggerService.error(error, {
          context: 'BookShelfService::getWorldReaderBooks - newBooks cant load books',
          feature: Feature.SHELF,
          status: error.status,
        });
      })) || [];

    const categoriesBooks: BookCategory[] =
      (await firstValueFrom(this.booksProvider.getBookCategories()).catch(error => {
        this.loggerService.error(error, {
          context: 'BookShelfService::getWorldReaderBooks - newBooks cant load books',
          feature: Feature.SHELF,
          status: error.status,
        });
      })) || [];

    this.categoriesBooks$.next(categoriesBooks);
    this.featuredBooks$.next(featuredBooks);
    this.popularBooks$.next(popularBooks);
    this.newBooks$.next(newBooks);
  }
}
